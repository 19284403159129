.payment {
  background-color: #f2f2f2;
  padding: 20px;
}

.card-section {
  display: block;
}

.other-section {
  display: none;
  text-align: center;
  min-height: 100px;
}

#payment-option {
  display: none;
}

.pay-title {
  padding-top:10px;
}

.pay-button {
  margin-bottom: 10px;;
}

.disableButton {
  opacity: 0.6;
  cursor: not-allowed !important;
}

@media (max-width: 414px) {
/*  .securecont {display: block;}
  .securelogo, .securetext {
      width: 100% !important;
  }*/
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

