.startchatgptv2 .banner_img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: 26,45,243;
}

.startchatgptv2 .parallax-container {
    height: 790px;
    overflow: hidden;
    position: relative;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#010219+0,00031b+49,000212+100 */
    background: rgb(1,2,25); /* Old browsers */
    background: -moz-linear-gradient(45deg,  rgba(1,2,25,1) 0%, rgba(0,3,27,1) 49%, rgba(0,2,18,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  rgba(1,2,25,1) 0%,rgba(0,3,27,1) 49%,rgba(0,2,18,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  rgba(1,2,25,1) 0%,rgba(0,3,27,1) 49%,rgba(0,2,18,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#010219', endColorstr='#000212',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

.startchatgptv2 .pcont {
    margin-top: 120px;
}

.startchatgptv2 .pcontent {
    height: 500px;
}
.startchatgptv2 .parallaximg {
    border: 10px solid black;
    border-radius: 20px 20px 0 0;
}

.startchatgptv2 .parallax-image {
    position: absolute;
    top: 500px;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/images/bg_flower.png');
    background-size: cover;
    background-position: 100%;
    transform: translate3d(0, 0, 0);
/*    z-index: 3;*/
}

.startchatgptv2 .parallax-content {
    position: inherit;
/*    z-index: 2;*/
}

.startchatgptv2 .useai {
    background: url(../assets/images/gradient1.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.startchatgptv2 .useaibg {
    background-image: url(../assets/images/g1.png);
    background-size: auto;
    background-repeat: repeat;
    background-position: center center;
}

.startchatgptv2 .mainbanner {
  height: 600px;
}
.startchatgptv2 iframe {
  width: 80%;
  height: 430px;
  border-radius: 10px;
}

.startchatgptv2 .cascadeimg {
    min-height: 240px;
    height: 31vw;
    width: 80%;
    max-width: 1200px;
    top: calc(var(--top) + var(--sticky-top) + var(--wix-ads-top-height) * var(--is-sticky));
    align-self: start;
    justify-self: center;
    margin-left: 0%;
    margin-right: 0.734501%;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    margin: 0 auto;
}

.startchatgptv2 .simpli4 {
    max-height: 457px;
}

.startchatgptv2 .simpli6 {
        max-height: 445px;
}

.startchatgptv2 .blkpic {
    height: auto;
    padding: 30px;
}

@media (max-width: 991px) {
    .startchatgptv2 .mainbanner {
      height: auto;
    }
}
@media (max-width: 767px) {
    .startchatgptv2 iframe {
      width: 100% !important;
      border-radius: 0px !important;
    }
}
@media (max-width: 640px) {
    .startchatgptv2 .third {
        padding: 20px;
    }
    .startchatgptv2 .parallax-container {
        height: 1400px
    }
    .startchatgptv2 .parallax-image {
        top: 700px;
    }
}