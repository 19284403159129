.pricing {
  background-color: #fff;
  padding: 20px 10px;
}
.v-pricing .price_col, .v-pricing-02 .price_col {
  /* width: 400px; */
}

.v-pricing-02 .arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.v-pricing-02 .tooltip {
  width: 320px;
}

.v-pricing-02 .tooltip_content {
  background-color: #fff;
  border: 3px solid #3b82f6;
  border-radius: 10px;
  box-shadow: 0 3px 15px 10px #fff;
  margin: 0 auto;
  padding: 10px;
  line-height: 1.5;
}
.v-pricing-02 .tooltip:before {
  border-bottom-color:rgba(0,0,0,0.095);
  border-bottom: 15px solid #3b82f6;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 0;
  content: "\0020";
  display: block;
  height: 0;
  left: 50px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  width: 30px;
  z-index: 2;
  transform: rotate(-180deg);
}
.v-pricing-02 .tooltip:after {
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 0;
  content: "\0020";
  display: block;
  height: 0;
  top: -7px;
  left: 50px;
  overflow: hidden;
  position: absolute;
  width: 30px;
  z-index: 2;
  transform: rotate(-180deg);
}

.v-pricing-02 .items {
  position: absolute;
  margin-top: -30px;
}

.v-pricing-02 .p0{z-index: 99;}
.v-pricing-02 .p1{z-index: 9;}

.v-pricing-01 .price_col {
  /* width: 500px; */
}

.price_col {
    text-align: center;
    max-width: 500px !important;
}
.price_col i.fas, .price_col i.fa {
    font-size: 1rem;
}
.price_col ul li > div::before {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  content: "\f00c";
  margin-right: 5px;
  color: #3b82f6;
  font-style: normal;
}
.v-pricing-02 .price_col ul li > div::before {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  content: "\f00c";
  margin-right: 10px;
  color: #3b82f6;
}
.v-pricing-01 .price_col ul li > div::before {
  position: absolute;
  left: 30px;
}
.price_col ul li>div {
  margin-bottom: 10px;
}

.v-pricing-02 .price_col ul li>div {
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
}

.v-pricing-02 .pricing-toggle .price_col ul li>div {
    position: initial;
}

.price_col > div {
  height: 100%;
  position: relative;
}

.price_col > div .price-content {
  margin-bottom: 50px;
}
.v-pricing .price_col > div button,
.v-pricing-02 .price_col > div button {
  position: absolute;
  bottom: 35px;
  left: calc(50% - 65px);
}
.plan-description {
  text-align: left;
  margin-left: 25px;
}

.span-highlight {
  height: auto !important;
  position: absolute !important;
}

.v-pricing-02 .mobile02 .tooltip_content {
  background-color: #f9f9f9;
  line-height: 1.5;
  margin: 0 auto;
  padding: 10px;
  border: none;
  margin-left: 15px;
  border-radius: 0;
  box-shadow: 0 3px 15px 10px #fff;
}
.v-pricing-02 .mobile02 .price_col ul li>div {
    margin-bottom: 10px;
    width: 100%;
    display: table;
}

.v-pricing-02 .mobile02 .v-pricing-02 .tooltip {
    width: 100%;
    position: relative;
    display: block;
}

.v-pricing-02 .mobile02 .tooltip:before, .v-pricing-02 .mobile02 .tooltip:after {
  display: none;
}

@media (max-width:768px){
  .v-pricing-02 .price_col ul li > div::before {
    content: "\f078";
  }
  .v-pricing-02 .tooltip {
    width: auto;
  }
}
@media (max-width: 500px) {
  .v-pricing-02 .mobile02 {
      width: 100% !important;
  }
  .price_col {
      width: 100% !important;
  }
}