.text-to-image .banner_img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../assets/images/bg1_blue.png);
  background-color: 26,45,243;
}


.useai {
    background: url(../assets/images/bg9.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
}

.text-to-image .mainbanner {
  height: 600px;
}
.text-to-image iframe {
  width: 80%;
  height: 430px;
  border-radius: 20px;
}

@media (max-width: 991px) {
  .text-to-image .mainbanner {
    height: auto;
  }
  .text-to-image iframe {
    width: 90% !important;
  }
}

@media (max-width: 500px) {
}
