
.pricing-toggle .price_col {
    /* width: 400px; */
    text-align: center;
    position: relative;
}
.pricing-toggle .price_col i.fas, .price_col i.fa {
    font-size: 1rem;
}
.pricing-toggle.price_col ul li > div::before {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  content: "\f00c";
  margin-right: 5px;
  color: #3b82f6;
  position: absolute;
  left: 30px;
}
.pricing-toggle .price_col ul li > div {
  margin-bottom: 10px;
}

.pricing-toggle .price_col > div {
  height: 100%;
  position: relative;
}

.pricing-toggle .price_col > div .price-content {
  margin-bottom: 50px;
}

.pricing-toggle .span-highlight {
  height: auto !important;
  position: absolute !important;
  width: calc(100% - 2rem);
  padding: 10px;
}

.pricing-toggle .plan-description {
  text-align: left;
  margin-left: 25px;
}

.price-content .p_main {
  font-weight: bold;
}

.price-content .p_sub {
  font-style: italic;
  text-align: left;
}

@media (max-width: 500px) {
  .pricing-toggle .price_col {
      width: 100% !important;
  }
}