.startchatgptgo .introbg {
    background: url(../assets/images/sunset.png);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 480px;
    position: relative;
}

.startchatgptgo .introbg img{
    margin: 0 auto;
    margin-top: -170px;
    width: 520px;
}
.startchatgptgo .parallax-container {
    height: 790px;
    overflow: hidden;
    position: relative;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#010219+0,00031b+49,000212+100 */
    background: rgb(1,2,25); /* Old browsers */
    background: -moz-linear-gradient(45deg,  rgba(1,2,25,1) 0%, rgba(0,3,27,1) 49%, rgba(0,2,18,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg,  rgba(1,2,25,1) 0%,rgba(0,3,27,1) 49%,rgba(0,2,18,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg,  rgba(1,2,25,1) 0%,rgba(0,3,27,1) 49%,rgba(0,2,18,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#010219', endColorstr='#000212',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}

.startchatgptgo .pcont {
    margin-top: 120px;
}

.startchatgptgo .pcontent {
    height: 500px;
}
.startchatgptgo .parallaximg {
    border: 10px solid black;
    border-radius: 20px 20px 0 0;
}

.startchatgptgo .parallax-image {
    position: absolute;
    top: 1100px;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/images/bg_flower.png');
    background-size: cover;
    background-position: 100%;
    transform: translate3d(0, 0, 0);
/*    z-index: 3;*/
}

.startchatgptgo .parallax-content {
    position: inherit;
/*    z-index: 2;*/
}

.startchatgptgo .useai {
    background: url(../assets/images/gradient1.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.startchatgptgo .useaibg {
    background-image: url(../assets/images/g1.png);
    background-size: auto;
    background-repeat: repeat;
    background-position: center center;
}


.startchatgptgo iframe {
  width: 100%;
  height: 80vh;
  border-radius: 5px;
}

.startchatgptgo .cascadeimg {
    min-height: 240px;
    height: 31vw;
    width: 80%;
    max-width: 1200px;
    top: calc(var(--top) + var(--sticky-top) + var(--wix-ads-top-height) * var(--is-sticky));
    align-self: start;
    justify-self: center;
    margin-left: 0%;
    margin-right: 0.734501%;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    margin: 0 auto;
}
.startchatgptgo .simpli1 {
    width: 30%;
    display: var(--l_display,var(--display));
    margin-left: -51%;
    margin-right: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    position: absolute;
    z-index: 6;
}
.startchatgptgo .simpli2 {
    width: 13%;
    display: var(--l_display,var(--display));
    margin-left: -43.78125%;
    margin-right: 0%;
    margin-top: 27.332682%;
    margin-bottom: 0%;
    position: absolute;
    z-index: 9;
}
.startchatgptgo .simpli3 {
    width: 15%;
    display: var(--l_display,var(--display));
    margin-left: -33.114583%;
    margin-right: 0%;
    margin-top: 15%;
    margin-bottom: 0%;
    position: absolute;
    z-index: 8;
}
.startchatgptgo .simpli4 {
    width: 54.99915443173049%;
    display: var(--l_display,var(--display));
    margin-left: -24%;
    margin-right: 0.0011313941634274475%;
    margin-top: 8%;
    margin-bottom: 0%;
    position: absolute;
    z-index: 6;
}
.startchatgptgo .simpli5 {
    height: auto;
    width: 16%;
    display: var(--l_display,var(--display));
    margin-left: 22%;
    margin-right: 0%;
    margin-top: 11.332%;
    margin-bottom: 0%;
    position: absolute;
    z-index: 9;
}
.startchatgptgo .simpli6 {
    height: auto;
    width: 18.875%;
    display: var(--l_display,var(--display));
    margin-left: 32%;
    margin-right: 0%;
    margin-top: 0.0012708333333269668%;
    margin-bottom: 0%;
    position: absolute;
}

.startchatgptgo .blkpic {
    height: auto;
    padding: 30px;
}

@media (max-width: 991px) {
    .startchatgptgo .mainbanner {
      height: auto;
    }
}
@media (max-width: 640px) {
    .startchatgptgo iframe {
      border-radius: 0px;
    }
    .startchatgptgo .introbg img{
        width: 90%;
    }
    .startchatgptgo .third {
        padding: 20px;
    }
    .startchatgptgo .parallax-container {
        height: 1400px
    }
    .startchatgptgo .parallax-image {
        top: 1100px;
    }
}