.login {
  background-color: #f2f2f2;
  padding: 20px;
}

.login .info-banner .close {
  margin-top: 9px;
}

.login .info-banner {
  top: 100px;
  left: 0px;
  text-align: center;
  width: 100%;
}

.login .info-banner > div {
  width: 75%;
  margin: auto;
}

.login .login-container {
  min-height: 500px;
}

@media screen and (max-width: 1350px) {
  .login .info-banner > div{
    text-align: left;
  }
}

@media screen and (max-width: 991px) {
  .login .info-banner > div {
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .login .info-banner > div {
    width: 90%;
  }
  .login .container {
    max-width: 320px;
  }
}

@media screen and (max-width: 400px) {
  .login {
    padding: 0px !important;
  }
}

@media screen and (max-width: 380px) {
  .login .info-banner > div {
    font-size: 14px;
    text-align: left;
  }
}

@media screen and (max-height: 800px) {
  .login .info-banner {
    position: relative !important;
    top: 80px !important;
  }
  .login-container {
    height: 80vh !important;
    display: block !important;
  }
}

.google-button {
  display: inline-flex;
  margin-top: 25px;
  width: 100%;
}
.google-button-icon {
  display: inline-flex;
  vertical-align: middle;
  margin-right: 5px;  
}
.google-button-icon img {
  width: 20px;
  max-height: 20px;
}
.google-button-text {
  display: inline-flex;
}