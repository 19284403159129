.pricing-upgrade {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
}
.pricing-upgrade .price_col {
/*    width: 400px;*/
    text-align: center;
    position: relative;
}
.pricing-upgrade .price_col i.fas, .price_col i.fa {
    font-size: 1rem;
}
.pricing-upgrade.price_col ul li > div::before {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  content: "\f00c";
  margin-right: 5px;
  color: #3b82f6;
  position: absolute;
  left: 30px;
}
.pricing-upgrade .price_col ul li > div {
  margin-bottom: 10px;
}

.pricing-upgrade .price_col > div {
  height: 100%;
  position: relative;
}

.pricing-upgrade .price_col > div .price-content {
  margin-bottom: 30px;
}

.pricing-upgrade .span-highlight {
  height: auto !important;
  position: absolute !important;
  width: calc(100% - 2rem);
  padding: 10px;
}

.pricing-upgrade .plan-description {
  text-align: left;
  margin-left: 5px;
}

@media (max-width: 1500px) {
  .plan-4 .price_col {
    width: 350px;
  }
}

@media (max-width: 1380px) {
  .plan-4 {
    flex-wrap: wrap;
    padding-left: 6%;
    padding-right: 6%;
  }
  .plan-4 .price_col {
    width: 380px;
  }
}

@media (max-width: 500px) {
  .pricing-upgrade .price_col {
      width: 100%;
  }
}