.myaccount .mytitle {
  min-height: 60px;
}

.myaccount .mydesc {
  min-height: 150px;
}

.myaccount .carousel .control-dots {
    margin-bottom: -12px !important;
    padding: 0px !important;
}

.myaccount .carousel .control-dots .dot {
      background: #3b82f6 !important;
}

.myaccount .carousel.carousel-slider .control-arrow {
  color: #3b82f6 !important;
}

.myaccount .carousel {
  text-align: left !important;
}
.myaccount .carousel .carousel-status {
    top: -13px !important;
    right: 10px !important;
     text-shadow: none !important;
    color: #84b6eb !important;
}

.myaccount .carousel.carousel-slider {
  overflow: initial !important;
}
.myaccount .pointer {
  cursor: pointer;
  color: rgb(59, 130, 246);
  font-weight: bold;
}

@media (max-width: 1280px) {
  .myaccount .mydesc {
    min-height: 170px;
  }
}

@media (max-width: 990px) {
  .myaccount .aichatbot .mydesc {
    min-height: 180px;
  }
}

@media (max-width: 964px) {
  .myaccount .aichatbot .mydesc {
    min-height: 210px;
  }
}

@media (max-width: 920px) and (min-width: 800px) {
  .myaccount .appbox a {
    font-size: 10px;
  }
}

@media (max-width: 899px) {
  .myaccount .mydesc {
    font-size: 13px;
  }
}

@media (min-width: 850px) {
  .myaccount .container {
    max-width: unset;
    width: 100%;
  }
  .myaccount .mytitle {
    justify-content: unset !important;
  }
  .myaccount .mytitle small{
    text-align: left !important;
  }
}

@media (max-width: 850px) {
  .myaccount .mydesc {
    min-height: 120px !important;
    margin-top: 10px;
  }
  .myaccount .mytitle img {
    width: 100px !important;
    height: 100px !important;
  }
  .aiart,
  .aichatbot {
    width: 100%;
  }
  .myaccount .mydesc {
    min-height: 120px;
    font-size: 16px;
  }
  .myaccount .appbox a {
    font-size: 14px;
  }
}

@media (max-width: 420px) {
  .myaccount .carousel .slide img {
    width: 70px !important;
    height: 70px !important;
  }
  .myaccount .carousel .control-dots {
    margin-bottom: -20px!important;
  }
  .myaccount.appbox {
      min-height: 340px;
  }
  .myaccount .mydesc {
    font-size: 14px !important;
  }
}

@media (max-width: 340px) {
  .myaccount .mydesc {
    font-size: 13px !important;
  }
}

/* ribbon */

.ribbon-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  /* height: 60px; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* .ribbon {
  width: 100%;
  text-align: center;
  background: orange;
  transform: rotate(45deg);
  height: 49px;
  position: absolute;
  top: -15px;
  right: -26px;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  padding: 2px;
} */


.ribbon {
  width: 100%;
  background: #ffd17d;
  border-radius: 7px 0 0 7px;
  box-shadow: 2px 3px 5px -5px #575757;
  height: 18px;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.ribbon.beta {
  background:#8befff;
  border-radius: 7px 0 0 7px;
  box-shadow: 2px 3px 5px -5px #575757;
}

.ribbon.popular{
  background-color: #7adf72;
  border-radius: 7px 0 0 7px;
  box-shadow: 2px 3px 5px -5px #575757;
}

/* end */

@media (min-width: 769px) and (max-width: 1023px) {
  .appbox .mytitle {
    display: flex;
    align-items: center;
    height: 94px;
    margin-left: 10px;
  }

  .appbox .mytitle .poweredby {
    display: block;
  }
}
