.payment {
  background-color: #f2f2f2;
  padding: 20px;
}
#tp-star-container {
  height: 80px;
  width: 150px;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  #tp-container {
    width: 100%;
    display: block;
    margin-top: -20px;
    margin-bottom: -10px;
  }

  #tp-container > div.flex {
    display: block;
    width: 100%;
    text-align: center;
  }

  #tp-container > div.flex > div {
    display: inline-block;
  }

  #tp-container .trustpilot-widget {
    margin-top: 10px !important;
  }

  .info-banner { margin-bottom: 10px; }
}