.home {
  background-color: #f2f2f2;
  padding: 20px;
}
/*.intro {z-index: 5;position: relative;}*/
.gradient-hover-effect {
  display: flex;
  padding: 0.875em 2em;
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}

.gradient-hover-effect:hover, .gradient-hover-effect:focus {
    background-color: #F7941D !important;
    color: #fff;
}

.text-gradient {
    background: linear-gradient(90deg, #3f51b5, transparent) #3fcad7;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

i.fas, i.fa {
    font-size: 1.5rem;
    margin: 0 0 0 7px;
}

.features i.fas, .features i.fa {
    font-size: 1rem;
    margin: 0 5px 0 0;
}
.ctabtn {
    box-shadow: 0 11px 12px -10px #282828 !important;
}

.ask {
    background: rgb(171,211,238);
    background: -moz-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: -webkit-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abd3ee', endColorstr='#f3f4f6',GradientType=1 );
}

.introsubtext { text-shadow: 0 1px 10px #f3f4f6;}

div#particles-js {
    position: absolute;
    z-index: 0 !important;
    top: 250px;
    height: 200px;
    width: 80%;
    margin: 0 auto;
    right: 0;
    left: 0;
}

@media (max-width: 991px) {
    div#particles-js {
        top: 150px;
        height: 100px;
    }
}


@media (max-width: 500px) {
    div#particles-js {
        height: 100px;
        top: 182px;
        width: 100%;
    }
}
