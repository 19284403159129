@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
.tp-review-con{
    position: relative;
    width: 850px;
    margin: 0 auto;
    box-sizing: border-box 
}
.tp-reviewer-container{
    text-align: center;
    height: 150px
}

.tp-review {
    background: #f3f4f6;
}
.tp-hear{
    text-align: center !important;
    font-size: 30px !important;
    color: #666666 !important;
    font-weight: 700 !important;
    margin-top: 50px;

}
.tp-review-box {
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding:50px;
    height: 360px;
    margin: 50px auto;
    filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
}
.tp-review-box:after {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 0 2px 2px 0 solid #fff;
    top: 100%;
    left: 50%;
    margin-left: -25px;
    content: '';
    transform: rotate(45deg);
    margin-top: -25px;
    background: #fff;
    z-index: -1;
  
}

.slick-slide.slick-active.slick-current{
    padding: 15px 50px;
}
p.title {
   
    color: #049AE6 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: center !important;
}
p.description {
    
    margin-top: 30px;
    color: #000 !important;
    line-height: 25px !important;
    font-size: 15px !important;
    font-weight: 100 !important;
}
.f9pricing p.description {
    font-weight: 400 !important;
}
.desc-container {
    position: relative;
}
.desc-container p{
    text-align: start !important;
}
p.tp-reviewer-name {
    font-size: 14px !important;
    font-weight: bold !important;
    margin-top: 20px;
    color: #000 !important;
}
span.tp-highlighted {
    background: #FFE600 !important;
    font-weight: 100 !important;
}
.f9pricing span.tp-highlighted {
    font-weight: 400 !important;
}
.tp-logo { 
    position: absolute;
    bottom: 20px;
    right: 20px;

}
.tp-reviewer.slick-slide {
    
    display: block;
    margin: 0 auto;
}


.slick-slide.slick-current.slick-active .tp-rev {
    
   
    padding: 20px;
    transform: scale(1.7);
    transition: ease-in-out 0.2s;
    transition-delay: 10ms;
    
    
}



.slick-prev, .slick-next{
    width: 30px !important;
    height: 30px !important;
}

.tp-img-reviewer {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.tp-reviewer-main.container{
    width: 1170px;
    position: relative;
}

 
@media only screen and (max-width : 991px){ /* tablet */
    .tp-review-con{
        width: auto;
    }

    .slick-slide.slick-current.slick-active .tp-rev {
        padding: 4px;
        margin-top: 10px;
    }
}

@media only screen and (min-width: 1024px){
    .slick-slide.slick-current.slick-active .tp-rev {
        padding: 15px;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 1025px){


    .tp-review-con{
        
        width: 700px;
        
    }
}

@media only screen and (max-width: 1023px){
    .slick-slide.slick-current.slick-active .tp-rev {
        padding: 4px;
        margin-top: 10px;
    }

}

@media only screen and (max-width: 1280px){
    .slick-slide.slick-current.slick-active .tp-rev {
        transform: scale(2);
    }
}

@media only screen and (max-width : 768px) { /* mobile */
    .tp-review-con{
        width: 250px;
    }
    .tp-hear{
        font-size: 3rem;
    }
    .tp-review-box{
        
        margin: 50px auto;
        height: 360px;
    }
    .tp-logo {
        position: relative;
        bottom: 0;
        right: 0;
        margin-top: 20px;
        text-align: center;
    }
    .tp-img-reviewer {
        display: block;
        text-align: center;
        margin: 0 auto;
    }
    .tp-logo img {
        display: inline;
    }
    p.tp-reviewer-name {
        margin-top: 30px;
        text-align: center;
        font-size: 12px !important;
    }
    .desc-container p{
        text-align: center !important;
        font-size: 12px !important;
    }
    p.title {
        font-size: 1.8rem !important;
    }
    .tp-reviewer.slick-slide.slick-current.slick-active {
        float: none;
        display: block;
        margin: 0 auto; /* This centers the image horizontally */
    }

    .slick-slide.slick-current.slick-active .tp-rev {
        padding: 20px;

      
    }

    .tp-review-box {
        padding: 15px;
        margin: 15px auto 50px;
    }

    .slick-slide.slick-active.slick-current{
        padding: 10px 50px;
    }

    .slick-slide.slick-current.slick-active .tp-rev {
        transition: none;
        transition-delay: none;
    }
}

@media (max-width: 640px){
    .container{
        max-width: 320px;
    }

    .tp-hear{
        text-align: center !important;
        font-size: 1.8rem !important;
        color: #666666 !important;
        font-weight: 700 !important;
        margin-top: 50px;
    
    }
    p.title {
   
        color: #049AE6 !important;
        font-size: 1.15rem !important;
        font-weight: 700 !important;
        text-align: center !important;
    }
    .tp-review-box {
        height: fit-content;
     
    }


}

@media only screen and (max-width : 500px) { /* mobile */
    .tp-review-con{
        width: 100% !important;
    }
    .slick-slide.slick-active.slick-current {
        padding: 10px 20px !important;
    }
}
@media (min-width: 1200px) {
    /* .container_f_03 .container{
        width: 940px !important;
    } */
    .container_f_03 .slick-prev, .container_f_03 .slick-next{
        width: 40px;
        height: 40px;
    }


    

}