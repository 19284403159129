html {
  scroll-behavior: smooth;
}
.back-to-top {
  visibility: hidden;
  position: absolute;
  right: 30px;
  font-size: 36px;
  bottom: 30px;
  color: #3f51b5;
}
.back-to-top.visible {
  position: fixed;
  right: 30px;
  font-size: 36px;
  cursor: pointer;
  bottom: 30px;
  color: #3f51b5;
  visibility: visible;
  opacity: 0.2;
  transition: visibility 0s linear, opacity 0.3s linear;
  z-index: 999;
}

.aiprologofooter {
	width: 250px;
	margin: 20px auto;
}

.text-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

.text-link:hover::after {
  content: "";
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #E5942E;
  animation: running-line 0.2s linear;
}

@keyframes running-line {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}