.stabledif .lp_img {width:100%;}
.stabledif .intro {z-index: 5;position: relative;}
.stabledif .cta { box-shadow: 0 10px 16px -11px black;}
.stabledif .gradient-hover-effect {
  display: flex;
  padding: 0.875em 2em;
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}
.stabledif .gradient-hover-effect:hover, .stabledif .gradient-hover-effect:focus {
  background-color: #F7941D !important;
  color: #fff;
}
.stabledif .text-gradient {
  background: linear-gradient(90deg, #3f51b5, transparent) #3fcad7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stabledif .ctabtn {
    box-shadow: 0 11px 12px -10px #282828 !important;
}
.stabledif .ask {
    background: rgb(171,211,238);
    background: -moz-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: -webkit-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abd3ee', endColorstr='#f3f4f6',GradientType=1 );
}
.stabledif .banner_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stabledif .introsubtext { text-shadow: 0 1px 10px #f3f4f6;}
.stabledif #particles-jsbg {
  position: absolute;
  width: 100% !important;
  height: 650px;
  top: 0px;
/*  background:url(../assets/images/bg3.png);
  background-size: cover;
  background-repeat: no-repeat;*/
  opacity:0.6;

}
.stabledif .carousel-status, .stabledif .thumbs-wrapper {display: none;}
.stabledif .carousel .slider-wrapper {width: 90% !important;}
.stabledif .carousel .control-dots .dot {background: #ddd !important;}
.stabledif .carousel .control-dots {margin-bottom: 10px !important;}
@media (max-width: 991px) {
    .stabledif div#particles-js {
    }
}

@media (max-width: 500px) {
    .stabledif div#particles-js {
    }
}
