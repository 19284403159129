.splash {
  background-color: #F3F4F6;
  padding: 20px 10px;
}

.splash .carousel-status {
  display: none;
}
.splash .carousel.carousel-slider {
  margin: 0 auto;
}
.splash .carousel .control-dots {
    margin-bottom: -20px !important;
    padding: 0px !important;
}
@media (max-width: 500px) {
  .splash .carousel .slide img {
      width: 60%;
    }
  .splash .mbcontain {display: block !important;}
}
