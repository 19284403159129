.payment {
  background-color: #f2f2f2;
  padding: 20px;
}

.carousel .control-dots {
    margin-bottom: -12px !important;
    padding: 0px !important;
}

.carousel .control-dots .dot {
      background: #3b82f6 !important;
}

.carousel.carousel-slider .control-arrow {
  color: #3b82f6 !important;
}

.carousel {
  text-align: left !important;
}
.carousel .carousel-status {
    top: -13px !important;
    right: 10px !important;
     text-shadow: none !important;
    color: #84b6eb !important;
}

.carousel.carousel-slider {
  overflow: initial !important;
}