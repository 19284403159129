.headerRef {
  display: absolute;
  width: 100%;
  top: 0;
}
.aiprologo {width: 150px;}
header {
  position: fixed;
  top: 25px;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.svg-icon {
  fill: white;
}
.headernav {
	box-shadow: 0 3px 19px -14px black;
  background: #ffffff;
}
.headerctabtn {
	padding: 5px 10px !important;
	border-radius: 15px !important;
	font-size: 0.9rem;
}

@media (max-width: 991px) {
  .headernav {
    width: 98% !important;
  }
}

@media (max-width: 500px) {
  .headernav {
    width: 90% !important;
  }
}

.headernav nav#menu ul li a:before {
  content: ">";
  display: inline-block;
  width: 7px; /* Set a fixed width */
  margin-right: 2px;
  opacity: 0; /* Set the initial opacity to 0 */
  transition: opacity 0.2s ease-in-out; /* Add a transition effect */
}

.headernav nav#menu ul li a:hover:before {
  opacity: 1; /* Set the opacity to 1 on hover */
}


@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.dropdown-wrapper {
  position: relative;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 5px;
  padding: 1rem 0;
  box-shadow: 0 7px 17px -10px black;
}

.dropdown li {
  margin: 0;
}

.dropdown a {
  display: block;
  padding: 0.5rem;
  width: 250px;
}

.dropdown-wrapper:hover .dropdown {
  display: block;
}

/* CSS */

/*.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}
.hamburger-menu.logged {
  position: absolute;
  right: 25px;
}
.bgline {
  width: 100%;
  height: 3px;
  background-color: #000000 !important;
  margin-bottom: 4px;
}*/

.menu-container {
  max-height: calc(90vh - 3.5rem); /* Adjust the height as needed */
  overflow-y: auto;
}


@media (min-width: 768px) {
  .mobilenav {
    display: none;
  }
}

@media (max-width: 991px){
  .headerStyle {
    max-width: 980px !important;
  }
}

.mobilenav .headnav{
    right: 0;
    background: #fff;
    text-align: left;
    padding: 15px;
    font-size: 14px;
    top: 72px;
    border-radius: 15px;
    box-shadow: 0 2px 13px -9px black;
    line-height: 2;
    max-height: 83vh;
    overflow-y: scroll;
}

/* Media query for mobile devices with a width of 991px and below */
@media (min-width: 0px) and (max-width: 991px) {
  /* Hide the menu by default */
  .headnav {
    display: none;
  }
  .ctaStartHere {margin: 0 !important;}
  .hamburger-menu {
    position: absolute;
    right: 25px;
  }
  /* Show the menu when the showMenu state is true */
  .headnav.show-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 10px;
  }

  /* Show the hamburger menu button */
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }

  .hamburger-line {
    width: 30px;
    height: 100%;
    height: 3px !important;
    background-color: #000 !important;
    margin-bottom: 4px;
  }
}

@media (max-width: 639px) {
  .aiprologo {width: 110px;}
  .headerctabtn {font-size: 0.8rem;}
  .headerctabtn.gradient-hover-effect.text-white.rounded-3xl.block.sm\:hidden.ml-auto.mr-3 {
      margin: 0;
  }

  .hamburger-menu {
    position: absolute;
    right: 25px;
  }
}