.startchatgpt .lp_img {width:100%;}
.startchatgpt .intro {z-index: 5;position: relative;}
.startchatgpt .cta { box-shadow: 0 10px 16px -11px black;}

.startchatgpt .gradient-hover-effect {
  display: flex;
  padding: 0.875em 2em;
  background: linear-gradient(90deg, #3f51b5, transparent) #2196f3;
  font-family: inherit;
  color: #fff;
  text-decoration: none;
  transition: background-color 1s;
  place-content: center;
}
.startchatgpt .banner_img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.startchatgpt .gradient-hover-effect:hover, .startchatgpt .gradient-hover-effect:focus {
  background-color: #F7941D !important;
  color: #fff;
}
.startchatgpt .text-gradient {
  background: linear-gradient(90deg, #3f51b5, transparent) #3fcad7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.startchatgpt .ctabtn {
    box-shadow: 0 11px 12px -10px #282828 !important;
}
.startchatgpt .ask {
    background: rgb(171,211,238);
    background: -moz-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: -webkit-radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    background: radial-gradient(ellipse at 50% 150%, rgba(171,211,238,1) 0%, rgba(235,241,246,1) 50%, rgba(236,241,246,1) 62%, rgba(243,244,246,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#abd3ee', endColorstr='#f3f4f6',GradientType=1 );
}
.startchatgpt .introsubtext { text-shadow: 0 1px 10px #f3f4f6;}
.startchatgpt .intro {
/*  position: absolute;*/
  width: 100% !important;
/*  height: 650px;*/
  top: 0px;
/*  background:url(../assets/images/bg4.png);
  background-size: cover;
  background-repeat: no-repeat;*/
/*  opacity:0.5;*/

}
.startchatgpt .carousel-status, .startchatgpt .thumbs-wrapper {display: none;}
.startchatgpt .carousel .slider-wrapper {width: 90% !important;}
.startchatgpt .carousel .control-dots .dot {background: #ddd !important;}
.startchatgpt i.fa {
    font-size: 2rem;
    display: block;
}

@media (max-width: 991px) {
    .startchatgpt div#particles-js {
    }
}

@media (max-width: 500px) {
    .startchatgpt div#particles-js {
    }
}
