#active-session, #active-session body {
  background: white;
}

@media (max-width: 650px) {
  #active-session .container {
    max-width: unset !important;
  }
}

@media (max-width: 450px) {
  #active-session .cont-working {
    display: block;
    margin-top: 10px;
  }

  #active-session .btn-stop-session {
    margin-right: 0px !important;
  }
}