.cm-scrollbar::-webkit-scrollbar {
  display: none;
}

.cm-scrollbar::-webkit-scrollbar-track {
  display: none;
}

.cm-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}

.cm-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #38505b;
  cursor: grab;
}

.subs-table .dp-top {
  margin-top: 5px;
}

.subs-table .dp-bot {
  bottom: 40px;
}

.container-full-width { width: 100%; }
tr.sub_tbl, .sub_tbl th {height: 45px !important;border: 1px solid #ddd;}

#headlessui-portal-root {
  position: relative;
  z-index: 100000;
}

#headlessui-portal-root .inset-0 {
  top: 0;
  width: 100%;
  height: 100%;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
/*  width: 80%;*/
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.ent-field-error {
  border: 1px solid red !important;
}

.member-error {
  color: red;
  display: none;
  font-size: 10px;
  font-weight: 100;
}

@media (max-width: 1024px) {
  .cm-scrollbar::-webkit-scrollbar-track {
    display: block;
    background-color: #f7f7f7;
  }
  .cm-scrollbar::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 10px;
  }
  .cm-scrollbar::-webkit-scrollbar-thumb {
    display: block;
    background-color: #4c6875;
    border-radius: 15px;
  }
}

@media (max-width: 640px){
  .total1 {
    display: block;
  }
  .total2 {
    display: block;
  }
}

@media (max-width: 414px){
  .container {
      max-width: 400px;
  }
}

